<template>
  <div class="page">
    <div class="segment">
      <div class="content narrow">
        <!-- <div class="header">BERGET 19 <span class="text-highlight">STORY</span></div> -->
        <!-- POST 1 -->
        <div class="post column">
          <div class="post-banner">
            <img src="@/assets/pagebanner-story.jpg">
          </div>
          <div class="post-content-wrapper">

            <div class="post-header">Background</div>
            <pre class="post-content">
              In the war-torn land of Langoi, nestled in a peaceful valley, lies an ancient monastery - Sacra Vallis. For over 900 years, the nuns of this monastery have guarded their sacred home, remaining neutral as the world’s conflicts raged around them. Legends speak of a hidden strength within the order, as the nuns secretly trained in martial arts to defend their sanctuary against raiders and warlords. In the 1200s, it’s said that some of the nuns joined the Crusades, protecting pilgrims and fighting alongside the elite Templar knights in the Holy Land.

              In modern times, the Sisters have earned a reputation for their compassion, helping the poor and providing aid during crises in the region. Their current leader, Mother Helene, has been a vocal advocate for peace. In recent years, she has openly opposed the ongoing conflicts in Langoi, urging for withdrawal of troops and calling for negotiations through various media outlets.

              But now, as conflict between Coalition forces and Republican Army closes in on their peaceful valley, the flames of conflict threaten to engulf their sanctuary once more.

              In the neighboring valley, at a Republican Army military base in the shadows of night, a high-ranking officer is assassinated. Witnesses report a female operative speeding away from the scene on a motorcycle. A drone is quickly deployed, tailing her as she escapes into the wilderness. She pulls over at a remote location, changes into nun’s clothing, and vanishes into the vast Sacra Vallis Monastery, carrying with her a stolen laptop containing sensitive information that could shift the balance of power in the upcoming negotiations to end the conflict in the area.

              REPA’s commanders, under pressure to recover the laptop and capture the female assassin, consider an artillery strike to obliterate the monastery and eliminate the problem. But a voice of reason prevails: “We can’t afford the fallout of destroying a monastery.” Instead, REPA quickly orders the deployment of drones and a strike team to track down and capture all nuns for interrogation and to find the laptop.

              Conflict is inevitable since the area is under Coalition Forces control who have small outposts in the valley. To the east the resistance forces have been seen accumulating soldiers across a river system and several REPA reconnaissance drones have been shot down. The nuns and the laptop needs to be captured before Coalition reinforcements can enter the Valley
            </pre>

          </div>

        </div>

        <div class="poster">
          <img src="../assets/2025/Berget-21-header-banner.jpg">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Story'
}
</script>
